import styles from "./marketplace-item.module.css";
import { MarketplaceItemCharacteristics } from "../marketplace-item-characteristics/marketplace-item-characteristics";
import { MarketplaceItemSkeleton } from "./marketplace-item-skeleton";
import { useIsSaleAvailable } from "../../../hooks/marketHooks/useIsSaleAvailable";
import { useParseEggData } from "../../../hooks/eggsHooks/useParseEggData";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useHatch } from "../../../hooks/eggsHooks/useHatch";
import { getEggsAddress } from "../../../services/eggs";
import { getChainId } from "../../../services/utils";
import toast from "react-hot-toast";
import eggsABI from "../../../assets/abis/breeder.json";
import { useGetSaleIdByToken } from "../../../hooks/marketHooks/useGetSaleIdByToken";

export const MarketplaceItemEgg = ({
  inventory = false,
  wishlist = false,
  tokenId = null,
  id,
  saleId = null,
  price = 0,
}) => {
  //USESTATE VARIABLES
  const [writeLoading, setLoadingWrite] = useState(false);

  //MARKET HOOKS
  const { isSaleAvailable, isSaleAvailableLoading } =
    useIsSaleAvailable(saleId);
  const { saleId: tokenSaleId, saleIdLoading } = useGetSaleIdByToken(
    id,
    getEggsAddress()
  );

  //EGGS HOOKS
  const { eggData, eggDataLoading } = useParseEggData(tokenId, tokenSaleId);
  const { hatchWrite, hatchError, isHatchError } = useHatch();

  //WAGMI HOOKS
  const navigate = useNavigate();

  const handleHatch = async () => {
    setLoadingWrite(true);
    try {
      await hatchWrite({
        abi: eggsABI,
        address: getEggsAddress(),
        functionName: "hatch",
        args: [tokenId],
        chainId: getChainId(),
      });
      toast.success("Egg hatched Successfully!");
      navigate("/inventory/fheral/pets");
      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  /* const handleMouseMove = (e) => {
    const container = $(`#marketplace-item${id}`)[0];

    const eTop = container.offsetTop;
    const eLeft = container.offsetLeft;
    const x = e.pageX;
    const y = e.pageY;

    $(`#modal-details${id}`)[0].style.top = y - eTop + 1 + "px";
    $(`#modal-details${id}`)[0].style.left = x - eLeft + 1 + "px";
  }; */

  useEffect(() => {
    if (isHatchError) {
      toast.error(hatchError.shortMessage);
    }
  }, [hatchError, isHatchError]);

  if (
    (isSaleAvailableLoading && !inventory) ||
    eggDataLoading ||
    saleIdLoading
  ) {
    return (
      <MarketplaceItemSkeleton
        id={id}
        inventory={inventory}
        wishlist={wishlist}
      />
    );
  }

  if (!inventory && !isSaleAvailable) {
    return <></>;
  }

  return (
    <div
      id={`marketplace-item${id}`}
      /* onMouseMove={handleMouseMove} */
      className={`${styles["marketplace-item"]} ${
        inventory && styles["inventory-item"]
      }`}
    >
      {/* {(!inventory || wishlist) && (
        <img
          className={styles.wishlist}
          src="/assets/images/marketplace/icons/wishlist-flag.svg"
          alt=""
        />
      )} */}
      <div className={styles["image-container-egg"]}>
        {inventory && eggData.onSale && (
          <img
            className={styles["for-sale"]}
            src={`/assets/images/marketplace/icons/forsale.png`}
            alt=""
          />
        )}
        <img
          className={styles.img}
          src={`/assets/images/marketplace/eggs/${eggData.type}.png`}
          alt=""
        />
      </div>
      <h3 className={styles.name}>{eggData.name}</h3>
      <MarketplaceItemCharacteristics classType={"egg"} type={eggData.type} />
      {!inventory && (
        <div className={styles.price}>
          <img src="/assets/images/icons/arker-icon.svg" alt="" />
          <span>{Math.floor(Number(price) * 100) / 100} ARKER</span>
        </div>
      )}

      <button className={`${styles["detail-button"]} see-detail-button`}>
        <Link
          to={`${
            inventory
              ? `/marketplace/inventory/egg/${tokenId}`
              : `/marketplace/egg/${tokenId}/${saleId}`
          }`}
        >
          See detail
        </Link>
      </button>

      {inventory && (
        <>
          {eggData.canHatch ? (
            <button
              className={`${styles["hatch-button"]} simple-button`}
              onClick={handleHatch}
            >
              Hatch!
            </button>
          ) : writeLoading ? (
            <button className={`${styles["hatch-button"]} simple-button`}>
              Loading…
            </button>
          ) : (
            <button className={`${styles["hatch-button"]} simple-button`}>
              {eggData.hoursToHatch ?? "Loading…"}
            </button>
          )}
        </>
      )}

      {/* <>
        <div id={`modal-details${id}`} className={styles["modal-details"]}>
          <div className={styles["modal-details-header"]}>
            <span>Item description</span>
          </div>
          <div className={styles.description}>
            <div className={styles.info}>
              <div className={styles["info-item"]}>
                <span className={styles["info-item-name"]}>Class:</span>
                <span className={styles["info-item-value"]}>EGG</span>
              </div>
              <div className={styles["info-item"]}>
                <span className={styles["info-item-name"]}>
                  Level Required:
                </span>
                <span className={styles["info-item-value"]}>2</span>
              </div>
              <div className={styles["info-item"]}>
                <span className={styles["info-item-name"]}>type:</span>
                <span className={styles["info-item-value"]}>wolf</span>
              </div>
              <div className={styles["info-item"]}>
                <span className={styles["info-item-name"]}>rarity:</span>
                <span className={styles["info-item-value"]}>
                  {eggData.rarity}
                </span>
              </div>
            </div>

            <p className={styles.text}>
              Restores energy to your hero each turn depending on their power.
            </p>
          </div>
          <div className={styles["modal-details-header"]}>
            <span>Status</span>
          </div>
          <div className={styles.stats}>
            <div className={styles.attack}>
              <img src="/assets/images/marketplace/stats/attack.svg" alt="" />
              <span>+15</span>
            </div>
            <div className={styles.life}>
              <img src="/assets/images/marketplace/stats/life.svg" alt="" />
              <span>+15</span>
            </div>
            <div className={styles.energy}>
              <img src="/assets/images/marketplace/stats/energy.svg" alt="" />
              <span>+15</span>
            </div>
            <div className={styles.agility}>
              <img src="/assets/images/marketplace/stats/agility.svg" alt="" />
              <span>+15</span>
            </div>
            <div className={styles.power}>
              <img src="/assets/images/marketplace/stats/power.svg" alt="" />
              <span>+15</span>
            </div>
          </div>
        </div>
      </> */}
    </div>
  );
};
