import styles from "./marketplace-item.module.css";
import $ from "jquery";
import { MarketplaceItemCharacteristics } from "../marketplace-item-characteristics/marketplace-item-characteristics";
import { MarketplaceItemSkeleton } from "./marketplace-item-skeleton";
import { Link } from "react-router-dom";
import { useIsSaleAvailable } from "../../../hooks/marketHooks/useIsSaleAvailable";
import { useParsePetData } from "../../../hooks/petsHooks/useParsePetData";
import { parseSkinNumToImage } from "../../../services/utils";
import { getPetsAddress } from "../../../services/pets";
import { useGetSaleIdByToken } from "../../../hooks/marketHooks/useGetSaleIdByToken";

export const MarketplaceItemPet = ({
  inventory = false,
  wishlist = false,
  tokenId = null,
  id,
  saleId = null,
  price = 0,
}) => {
  //MARKET HOOKS
  const { isSaleAvailable, isSaleAvailableLoading } =
    useIsSaleAvailable(saleId);
  const { saleId: tokenSaleId, saleIdLoading } = useGetSaleIdByToken(
    id,
    getPetsAddress()
  );

  //PET HOOKS
  const { petData, petDataLoading } = useParsePetData(tokenId, tokenSaleId);

  const handleMouseMove = (e) => {
    const container = $(`#marketplace-item${id}`)[0];

    const eTop = container.offsetTop;
    const eLeft = container.offsetLeft;
    const x = e.pageX;
    const y = e.pageY;

    $(`#modal-details${id}`)[0].style.top = y - eTop + 1 + "px";
    $(`#modal-details${id}`)[0].style.left = x - eLeft + 1 + "px";
  };

  if (
    saleIdLoading ||
    petDataLoading ||
    (isSaleAvailableLoading && !inventory)
  ) {
    return (
      <MarketplaceItemSkeleton
        id={id}
        inventory={inventory}
        wishlist={wishlist}
      />
    );
  }

  if (!inventory && !isSaleAvailable) {
    return <></>;
  }

  return (
    <div
      id={`marketplace-item${id}`}
      onMouseMove={handleMouseMove}
      className={`${styles["marketplace-item"]} ${
        inventory && styles["inventory-item"]
      }`}
    >
      {/* {(!inventory || wishlist) && (
        <img
          className={styles.wishlist}
          src="/assets/images/marketplace/icons/wishlist-flag.svg"
          alt=""
        />
      )} */}
      <div className={styles["image-container-fheral"]}>
        {inventory && petData.onSale && !petData.isBanned && (
          <img
            className={styles["for-sale"]}
            src={`/assets/images/marketplace/icons/forsale.png`}
            alt=""
          />
        )}

        {petData.isBanned && (
          <img
            className={styles["banned"]}
            src={`/assets/images/marketplace/icons/isbanned.png`}
            alt=""
          />
        )}
        <img
          className={styles["img-pet"]}
          src={`/assets/images/marketplace/pets/${
            petData.type
          }/${parseSkinNumToImage(petData.skin)}.gif`}
          alt=""
        />
      </div>
      <h3 className={styles.name}>{petData.name}</h3>
      <MarketplaceItemCharacteristics
        rarity={petData.rarity}
        classType={"fheral"}
        genre={petData.genre ?? undefined}
        type={petData.type}
      />
      {!inventory && (
        <div className={styles.price}>
          <img src="/assets/images/icons/arker-icon.svg" alt="" />
          <span>{Math.floor(Number(price) * 100) / 100} ARKER</span>
        </div>
      )}

      <button className={`${styles["detail-button"]} see-detail-button`}>
        <Link
          to={`${
            inventory
              ? `/marketplace/inventory/pet/${tokenId}`
              : `/marketplace/pet/${tokenId}/${saleId}`
          }`}
        >
          See detail
        </Link>
      </button>

      <div id={`modal-details${id}`} className={styles["modal-details"]}>
        <div className={styles["modal-details-header"]}>
          <span>Item description</span>
        </div>
        <div className={styles.description}>
          <div className={styles.info}>
            <div className={styles["info-item"]}>
              <span className={styles["info-item-name"]}>Class:</span>
              <span className={styles["info-item-value"]}>PET</span>
            </div>
            <div className={styles["info-item"]}>
              <span className={styles["info-item-name"]}>type:</span>
              <span className={styles["info-item-value"]}>{petData.type}</span>
            </div>
            <div className={styles["info-item"]}>
              <span className={styles["info-item-name"]}>rarity:</span>
              <span className={styles["info-item-value"]}>
                {petData.rarity}
              </span>
            </div>
          </div>

          <p className={styles.text}>{petData.description}</p>
        </div>
        <div className={styles["modal-details-header"]}>
          <span>Status</span>
        </div>
        <div className={styles.stats}>
          <div className={styles.attack}>
            <img src="/assets/images/marketplace/stats/attack.svg" alt="" />
            <span>+{petData.attack}</span>
          </div>
          <div className={styles.life}>
            <img src="/assets/images/marketplace/stats/life.svg" alt="" />
            <span>+{petData.life}</span>
          </div>
          <div className={styles.energy}>
            <img src="/assets/images/marketplace/stats/energy.svg" alt="" />
            <span>+{petData.energy}</span>
          </div>
          <div className={styles.agility}>
            <img src="/assets/images/marketplace/stats/agility.svg" alt="" />
            <span>+{petData.agility}</span>
          </div>
          <div className={styles.power}>
            <img src="/assets/images/marketplace/stats/power.svg" alt="" />
            <span>+{petData.power}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
