import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./marketplace-pet-info.module.css";
import { useQuery } from "@apollo/client";
import {
  SaleItemQuery,
  historyQuery,
} from "../../../../services/graphql-queries";
import { useEffect, useState } from "react";
import { getPetsAddress } from "../../../../services/pets";
import { useAccount, useBlockNumber, useChainId, useSwitchChain } from "wagmi";
import { useApprovedForAll } from "../../../../hooks/petsHooks/useApprovedForAll";
import { useMaxExpireDate } from "../../../../hooks/marketHooks/useMaxExpireDate";
import { useBuy } from "../../../../hooks/marketHooks/useBuy";
import { useSell } from "../../../../hooks/marketHooks/useSell";
import { useBreedCount } from "../../../../hooks/eggsHooks/useBreedCount";
import { useMaxBreeds } from "../../../../hooks/eggsHooks/useMaxBreeds";
import { useAllowance } from "../../../../hooks/tokenHooks/useAllowance";
import { useApprove } from "../../../../hooks/tokenHooks/useApprove";
import { useApproveForAll } from "../../../../hooks/petsHooks/useApproveForAll";
import toast, { Toaster } from "react-hot-toast";
import { useParsePetData } from "../../../../hooks/petsHooks/useParsePetData";
import petsABI from "../../../../assets/abis/pets.json";
import marketABI from "../../../../assets/abis/marketplace.json";
import {
  MAX_INT,
  getArkerTokenAddress,
  getChainId,
  getMarketAddress,
  parseSkinNumToImage,
} from "../../../../services/utils";
import { erc20Abi, parseEther } from "viem";
import moment from "moment";
import { getEggsAddress } from "../../../../services/eggs";
import { MarketplaceRelatedItems } from "../../marketplace-related-items/marketplace-related-items";
import { MarketplaceItemCharacteristics } from "../../marketplace-item-characteristics/marketplace-item-characteristics";
import { getExplorerURL, truncateETHAddress } from "../../../../services/utils";
import { useGetParents } from "../../../../hooks/eggsHooks/useGetParents";
import { ParentsInfo } from "./parents-info/parents-info";
import { useGetSaleIdByToken } from "../../../../hooks/marketHooks/useGetSaleIdByToken";
import { useParseGetSale } from "../../../../hooks/marketHooks/useGetOnSale";

const PETS_ADDRESS = getPetsAddress();
const EGGS_ADDRESS = getEggsAddress();

export const MarketplacePetInfo = () => {
  //LOCATION VARIABLES
  const { pathname } = useLocation();
  const pathnameArray = pathname.split("/");
  const inventory = pathnameArray[2] === "inventory" ?? false;
  const saleId = !inventory && pathnameArray[4] ? pathnameArray[4] : 0;
  const id = inventory ? pathnameArray[4] : pathnameArray[3];
  const classType = inventory ? pathnameArray[3] : pathnameArray[2];

  //USESTATE VARIABLES
  const [itemData, setItemData] = useState();
  const [itemHistory, setItemHistory] = useState();
  const [loadingWrite, setLoadingWrite] = useState(false);
  const [expire, setExpire] = useState();
  const [amount, setAmount] = useState();
  const [showDescription, setShowDescription] = useState(true);
  const [showStatus, setShowStatus] = useState(true);
  const [showItemActivity, setShowItemActivity] = useState(true);

  //GRAPHQL QUERY
  const { saleItemQueryLoading } = useQuery(SaleItemQuery, {
    context: {
      clientName:
        process.env.REACT_APP_PRODUCTION === "1"
          ? "marketplaceEndpointProd"
          : "marketplaceEndpointTest",
    },
    variables: { saleId: parseInt(saleId) },
    onCompleted(queryData) {
      setItemData(queryData.saleEntities[0]);
    },
    onError(error) {
      console.log(error);
    },
    skip: inventory,
  });

  const { itemHistoryQueryLoading } = useQuery(historyQuery, {
    context: {
      clientName:
        process.env.REACT_APP_PRODUCTION === "1"
          ? "marketplaceEndpointProd"
          : "marketplaceEndpointTest",
    },
    variables: {
      tokenAddress: PETS_ADDRESS,
      tokenId: parseInt(id),
    },
    onCompleted(queryData) {
      setItemHistory(queryData.saleEntities);
    },
    onError(error) {
      console.log(error);
    },
  });

  //WAGMI HOOKS
  const { address } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const navigate = useNavigate();
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();

  //EGGS HOOKS
  const { breedCount, breedCountLoading } = useBreedCount(id);
  const { maxBreeds, maxBreedsLoading } = useMaxBreeds();
  const { petParents, petParentsLoading } = useGetParents(id);

  //MARKET HOOKS
  const { maxExpireDate, maxExpireDateLoading } = useMaxExpireDate();
  const { buyItemWrite, buyItemError, isBuyItemError } = useBuy();
  const { sellItemWrite, sellItemError, isSellItemError } = useSell();
  const { saleId: tokenSaleId, saleIdLoading } = useGetSaleIdByToken(
    id,
    getPetsAddress()
  );

  //PETS HOOKS
  const { petData, petDataLoading } = useParsePetData(id, tokenSaleId);
  const { isApprovedForAll, refetchApprovedForAll } = useApprovedForAll();

  //TOKEN HOOKS
  const { allowance, refetchAllowance } = useAllowance(getMarketAddress());
  const { approveWrite, approveError, isApproveError } = useApprove();
  const { approveForAllWrite, approveForAllError, isApproveForAllError } =
    useApproveForAll();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    refetchAllowance();
    refetchApprovedForAll();
  }, [blockNumber, refetchAllowance, refetchApprovedForAll]);

  useEffect(() => {
    if (isSellItemError) {
      toast.error(sellItemError.shortMessage);
    }

    if (isApproveForAllError) {
      toast.error(approveForAllError.shortMessage);
    }
    if (isApproveError) {
      toast.error(approveError.shortMessage);
    }
    if (isBuyItemError) {
      toast.error(buyItemError.shortMessage);
    }
  }, [
    sellItemError,
    isSellItemError,
    approveForAllError,
    isApproveForAllError,
    buyItemError,
    isBuyItemError,
    approveError,
    isApproveError,
  ]);

  const handleBuyItem = async () => {
    setLoadingWrite(true);

    const date = parseInt(new Date().getTime() / 1000 + "", undefined) + 86400;

    try {
      await buyItemWrite({
        abi: marketABI,
        address: getMarketAddress(),
        functionName: "buy",
        args: [saleId, date],
        chainId: getChainId(),
      });

      toast.success("Item buyed Successfully!");
      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  const handleApproveArker = async () => {
    setLoadingWrite(true);
    try {
      await approveWrite({
        abi: erc20Abi,
        address: getArkerTokenAddress(),
        functionName: "approve",
        args: [getMarketAddress(), MAX_INT],
        chainId: getChainId(),
      });
      toast.success("Approve ARKER Successfully!");
      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  const handleApproveForAll = async () => {
    setLoadingWrite(true);
    try {
      await approveForAllWrite({
        abi: petsABI,
        address: PETS_ADDRESS,
        functionName: "setApprovalForAll",
        args: [getMarketAddress(), true],
        chainId: getChainId(),
      });
      toast.success("Approve for all tokens Successfully!");
      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  const handleSellItem = async () => {
    setLoadingWrite(true);

    const startTime = moment();
    const endTime = moment(expire);

    const secondsDiff = endTime.diff(startTime, "seconds");

    if (secondsDiff <= 0) {
      toast.error("The expire date needs to be in the future");
      setLoadingWrite(false);
      return;
    }

    if (secondsDiff > maxExpireDate) {
      toast.error(
        `You can't set a date longer than ${Math.floor(
          parseInt(maxExpireDate) / (3600 * 24)
        )} days`
      );
      setLoadingWrite(false);
      return;
    }

    if (!amount) {
      toast.error("Set an amount");
      setLoadingWrite(false);
      return;
    }

    try {
      await sellItemWrite({
        abi: marketABI,
        address: getMarketAddress(),
        functionName: "sell",
        args: [
          classType === "pet" ? PETS_ADDRESS : EGGS_ADDRESS,
          parseInt(id),
          parseFloat(parseEther(amount ?? "0")),
          secondsDiff,
        ],
        chainId: getChainId(),
      });
      setLoadingWrite(false);
      toast.success("Sell Order Placed Successfully!");
      setAmount(0);
      setExpire();
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  if (
    saleItemQueryLoading ||
    maxExpireDateLoading ||
    itemHistoryQueryLoading ||
    petDataLoading ||
    petParentsLoading ||
    breedCountLoading ||
    maxBreedsLoading ||
    saleIdLoading
  ) {
    return <h1 className="text-center my-5 text-white">Loading…</h1>;
  }

  return (
    <>
      <Toaster />
      <div className={`${styles["marketplace-pet-info-container"]}`}>
        <div className={styles.breadcrumbs}>
          <span onClick={() => navigate(-1)}>
            {!inventory ? "Market" : "Inventory"} &gt; {classType} &gt;{" "}
            {petData.type} &gt; {petData.name}
          </span>
        </div>

        <div className={styles.top}>
          <div className={`${styles["image-container"]}`}>
            {inventory && petData.onSale && !petData.isBanned && (
              <img
                className={styles["for-sale"]}
                src={`/assets/images/marketplace/icons/forsale.png`}
                alt=""
              />
            )}

            {petData.isBanned && (
              <img
                className={styles["banned"]}
                src={`/assets/images/marketplace/icons/isbanned.png`}
                alt=""
              />
            )}
            <div className={`${styles["pet-image-container"]}`}>
              <img
                className={`${styles["pet-image"]} ${
                  petData.type === "crab" && styles["pet-image-crab"]
                } ${petData.type === "phoenix" && styles["pet-image-phoenix"]}`}
                src={`/assets/images/marketplace/pets/${
                  petData.type
                }/${parseSkinNumToImage(petData.skin)}.gif`}
                alt=""
              />
            </div>
          </div>

          <div className={styles["pet-info"]}>
            <h1>{petData.name}</h1>
            <MarketplaceItemCharacteristics
              rarity={petData.rarity}
              classType={"fheral"}
              genre={petData.genre ?? undefined}
              type={petData.type}
            />
            {!inventory && (
              <span className={styles.available}>
                Available until{" "}
                {moment.unix(itemData?.expire).format("MMMM DD, YYYY")}
              </span>
            )}
            {!inventory && (
              <div className={styles["current-price"]}>
                <span>Current price</span>
                <div className={styles.price}>
                  <img src="/assets/images/icons/arker-icon.svg" alt="" />
                  <span>{Math.floor(Number(itemData?.amount) * 100) / 100} ARKER</span>
                </div>
              </div>
            )}

            <div className={styles["buttons-container"]}>
              {!inventory && !saleItemQueryLoading && (
                <>
                  {!address ? (
                    <button
                      className={`${styles["connect-wallet-button"]} ${styles["disabled"]} button-green`}
                    >
                      Connect wallet first
                    </button>
                  ) : (
                    <>
                      {chainId !== getChainId() ? (
                        <button
                          className={`${styles["buy-button"]} button-green`}
                          onClick={() =>
                            switchChain({
                              chainId: getChainId(),
                            })
                          }
                        >
                          {loadingWrite ? "Loading…" : "Change network"}
                        </button>
                      ) : (
                        <>
                          {allowance < parseEther(itemData?.amount ?? "0") ? (
                            <button
                              className={`${styles["buy-button"]} button-green`}
                              onClick={handleApproveArker}
                            >
                              {loadingWrite ? "Loading…" : "Approve ARKER"}
                            </button>
                          ) : (
                            <>
                              {itemData?.wallet.toLowerCase() ===
                              address?.toLowerCase() ? (
                                <button
                                  className={`${styles["buy-button"]} ${styles["disabled"]} button-green`}
                                >
                                  You're the owner
                                </button>
                              ) : (
                                <button
                                  className={`${styles["buy-button"]} button-green`}
                                  onClick={handleBuyItem}
                                >
                                  {loadingWrite ? "Loading…" : "Buy Now"}
                                </button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {classType === "pet" ? (
                <>
                  {inventory && (
                    <div className={styles["inventory-buttons-container"]}>
                      {isApprovedForAll ? (
                        <div className={styles["sell-container"]}>
                          <input
                            onChange={(e) => setAmount(e.target.value)}
                            type="text"
                            placeholder="Amount"
                            className={styles["amount-input"]}
                          />
                          <input
                            onChange={(e) => setExpire(e.target.value)}
                            type="text"
                            placeholder="Expire Date"
                            onFocus={(e) => (e.target.type = "datetime-local")}
                            onBlur={(e) => (e.target.type = "text")}
                            className="mt-2"
                          />
                          <button
                            className={`${styles["buy-button"]} button-green ${
                              !amount || !expire || loadingWrite ? (
                                styles.disabled
                              ) : (
                                <></>
                              )
                            }`}
                            disabled={!amount || !expire}
                            onClick={handleSellItem}
                          >
                            {loadingWrite ? "Loading…" : "Place sell order"}
                          </button>
                        </div>
                      ) : (
                        <button
                          className={`${styles["buy-button"]} button-green`}
                          onClick={handleApproveForAll}
                        >
                          {loadingWrite ? "Loading…" : "Approve"}
                        </button>
                      )}

                      <Link to={"/inventory/fheral/breeding"}>
                        <button
                          className={`${styles["add-wishlist-button"]} see-detail-button`}
                        >
                          Breeding
                        </button>
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                <button
                  className={`${styles["add-wishlist-button"]} see-detail-button`}
                >
                  Add to wishlist
                </button>
              )}
            </div>
            <p className={styles.owned}>
              Owned by{" "}
              <a
                href={`${getExplorerURL()}/address/${itemData?.wallet}`}
                target="_blank"
                rel="noreferrer"
              >
                {!inventory
                  ? truncateETHAddress(itemData?.wallet) ?? "unkown"
                  : "You"}
              </a>
            </p>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles["pet-description-container"]}>
            <div
              className={`${styles["pet-description"]} ${styles["pet-description-chest"]}`}
            >
              <div
                className={styles["description-header"]}
                onClick={() => setShowDescription(!showDescription)}
              >
                <span>pet description</span>
                <img
                  className={`${showDescription && styles["rotate"]}`}
                  src="/assets/images/icons/bottom-arrow-icon-green.svg"
                  alt=""
                />
              </div>

              <>
                <div
                  className={`${styles["description-content"]} ${
                    showDescription && styles["description-content-show"]
                  }`}
                >
                  <div className={styles.info}>
                    <div className={styles["info-pet"]}>
                      <span className={styles["info-pet-name"]}>Class:</span>
                      <span className={styles["info-pet-value"]}>
                        {classType}
                      </span>
                    </div>
                    <div className={styles["info-pet"]}>
                      <span className={styles["info-pet-name"]}>type:</span>
                      <span className={styles["info-pet-value"]}>
                        {petData.type}
                      </span>
                    </div>
                    <div className={styles["info-pet"]}>
                      <span className={styles["info-pet-name"]}>rarity:</span>
                      <span className={styles["info-pet-value"]}>
                        {petData.rarity}
                      </span>
                    </div>
                  </div>
                  <div className={styles["description-text"]}>
                    <p>{petData.description}</p>
                  </div>
                </div>

                <>
                  <div
                    className={styles["description-header"]}
                    onClick={() => setShowStatus(!showStatus)}
                  >
                    <span>status</span>
                    <img
                      className={`${showStatus && styles["rotate"]}`}
                      src="/assets/images/icons/bottom-arrow-icon-green.svg"
                      alt=""
                    />
                  </div>

                  <div
                    className={`${styles.stats} ${
                      showStatus && styles["stats-show"]
                    }`}
                  >
                    <div className={styles.attack}>
                      <img
                        src="/assets/images/marketplace/stats/attack.svg"
                        alt=""
                      />
                      <span>Attack +{petData.attack}</span>
                    </div>
                    <div className={styles.life}>
                      <img
                        src="/assets/images/marketplace/stats/life.svg"
                        alt=""
                      />
                      <span>Life +{petData.life}</span>
                    </div>
                    <div className={styles.energy}>
                      <img
                        src="/assets/images/marketplace/stats/energy.svg"
                        alt=""
                      />
                      <span>Energy +{petData.energy}</span>
                    </div>
                    <div className={styles.agility}>
                      <img
                        src="/assets/images/marketplace/stats/agility.svg"
                        alt=""
                      />
                      <span>Agility +{petData.agility}</span>
                    </div>
                    <div className={styles.power}>
                      <img
                        src="/assets/images/marketplace/stats/power.svg"
                        alt=""
                      />
                      <span>Power +{petData.power}</span>
                    </div>
                  </div>
                </>
              </>
            </div>

            {classType === "pet" && !itemHistoryQueryLoading && (
              <div className={styles["pet-activity"]}>
                <div
                  className={styles["description-header"]}
                  onClick={() => setShowItemActivity(!showItemActivity)}
                >
                  <span>Item activity</span>
                  <img
                    className={`${showItemActivity && styles["rotate"]}`}
                    src="/assets/images/icons/bottom-arrow-icon-green.svg"
                    alt=""
                  />
                </div>
                <div
                  className={`${styles["pet-activity-content"]} ${
                    showItemActivity && styles["pet-activity-content-show"]
                  }`}
                >
                  {itemHistory?.length === 0 || itemHistory === undefined ? (
                    <div
                      className={`${styles["pet-activity-content"]} ${
                        showItemActivity && styles["pet-activity-content-show"]
                      }`}
                    >
                      <ul>
                        <li>Event</li>
                        <li>Price</li>
                        <li>To(Wallet)</li>
                        <li>Buyed(Date)</li>
                      </ul>

                      <p>This NFT doesn't have any trade</p>
                    </div>
                  ) : (
                    <table className={styles["pet-activity-table"]}>
                      <thead>
                        <tr>
                          <th>Event</th>
                          <th>Price</th>
                          <th>To (Wallet)</th>
                          <th>Buyed(Date)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemHistory?.map((sale, i) => {
                          return (
                            <tr key={`pet-activity-${i}`}>
                              <td>Sale</td>
                              <td>{sale.amount} ARKER</td>
                              <td>{truncateETHAddress(sale.wallet)}</td>
                              <td>
                                {sale.buyed.toString() === "true"
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            )}
          </div>

          <ParentsInfo
            breedCount={breedCount}
            maxBreeds={maxBreeds}
            parents={petParents}
            type={petData.type}
            petId={id}
          />
        </div>
        {!inventory && <MarketplaceRelatedItems />}
      </div>
    </>
  );
};
